import {
    Directive,
    ElementRef,
    Renderer2,
    HostListener,
    HostBinding,
    Input
} from '@angular/core';

@Directive({
    selector: '[appHighlightOnHover]'
})
export class HighlightOnHoverDirective {

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2) { }

    ngOnInit() {
        this.color = this.defaultColor;
    }


    @Input() defaultColor: any = '';
    @Input() highlight: string = 'lime';

    @HostBinding('style.color') color: string = this.defaultColor;


    @HostListener('mouseenter') mouseover() {
        this.renderer.setStyle(this.elementRef.nativeElement, 'background-color', 'blue');

        this.color = this.highlight;
    }

    @HostListener('mouseleave') mouseleave() {
        this.renderer.setStyle(this.elementRef.nativeElement, 'background-color', 'transparent');
        this.color = this.defaultColor;
    }

}
