import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dateToFrench'
})
export class DateToFrenchPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            const datePipe = new DatePipe('fr-FR'); // Use the French locale
            return datePipe.transform(value, 'shortDate'); // Adjust the format as needed
        }
        return null;
    }

}
