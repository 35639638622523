import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { EventMessage, InteractionStatus } from '@azure/msal-browser';
import { PrimeNGConfig } from 'primeng/api';
import { Subject, Subscription, catchError, filter, fromEvent, map, merge, of, takeUntil, throwError } from 'rxjs';
import { UserAdService } from './main/service/user-ad.service';
import { LocationStrategy } from '@angular/common';
import { Insights } from './main/components/insights/insights.service';
import { NotifierService } from './layout/service/signal-r/notifier.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    networkStatus: boolean = false;
    networkStatus$: Subscription = Subscription.EMPTY;

    isIframe = false;
    loginDisplay = false;
    private readonly _destroying$ = new Subject<void>();

    private unsubscriber: Subject<void> = new Subject<void>();

    constructor(private insights: Insights,
        private _notifier: NotifierService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private _userAd: UserAdService,
        private _router: Router,
        private location: LocationStrategy,
        private primengConfig: PrimeNGConfig) {
        // preventing back button in browser implemented by "Samba Siva"
        history.pushState(null, 'null', window.location.href);
        this.location.onPopState(() => {
            history.pushState(null, 'null', window.location.href);
        });
        this.isIframe = window !== window.parent && !window.opener;
        this.authService.instance.enableAccountStorageEvents();
        this.msalBroadcastService.msalSubject$
            .subscribe((result: EventMessage) => {
                if (this.authService.instance.getAllAccounts().length === 0) {
                } else {
                    this.setLoginDisplay();
                }
            }
            );
        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                    (status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe((userAD) => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
            });
            this._notifier.startConnection();
    }

    ngOnInit(): void {
        this._userAd.UserLoggedIn()
            .pipe(
                catchError(err => {
                    return throwError(() => err);
                })
            )
            .subscribe(
                {
                    next: (_connectedUser: any) => {
                        if (_connectedUser) {
                            this._userAd.setUser(_connectedUser);
                            let userGroupsStringCollections: string[] = [];
                            _connectedUser.userGroups.map(
                                (_: any) => {
                                    if (_) {
                                        userGroupsStringCollections.unshift(_.displayName);
                                    }
                                }
                            );
                            (userGroupsStringCollections.includes('Store'))
                                ? this._userAd.IsStore(true)
                                : this._userAd.IsStore(false);
                        }
                    }
                }
            );
        this.checkNetworkStatus();
        this.primengConfig.ripple = true;
    }

    setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }

    checkAndSetActiveAccount() {
        let activeAccount = this.authService.instance.getActiveAccount();
        if (this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        } else {
            this._router.navigateByUrl('/');
        }
    }

    checkNetworkStatus() {

        this.networkStatus = navigator.onLine;
        this.networkStatus$ = merge(
            of(null),
            fromEvent(window, 'online'),
            fromEvent(window, 'offline')
        )
            .pipe(map(() => navigator.onLine))
            .subscribe(status => {
                this.networkStatus = status;
                if (!status) {
                    this._router.navigateByUrl('/offline-exception');
                }
            });
    }

}
