import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { MsalGuard } from '@azure/msal-angular';


const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    {
        path: 'app-layout',
        component: AppLayoutComponent,
        children: [
            { path: 'ooredoo-operations', data: { breadcrumb: "Opération d'achat", fragment: 'assets/demo/images/checkout.png' }, loadChildren: () => import('./main/components/ooredoo-operations/ooredoo-operations.module').then(m => m.OoredooOperationsModule), canActivate: [MsalGuard] },
            { path: 'ooredoo-tracking', data: { breadcrumb: "Suivi & Reporting", fragment: 'assets/demo/images/suivi.png' }, loadChildren: () => import('./main/components/ooredoo-tracking/ooredoo-tracking.module').then(m => m.OoredooTrackingModule), canActivate: [MsalGuard] },
            { path: 'ooredoo-exceptionnal-authorization', data: { breadcrumb: "Dérogation", fragment: 'assets/demo/images/warning.png' }, loadChildren: () => import('./main/components/ooredoo-exceptionnal-authorization/ooredoo-exceptionnal-authorization.module').then(m => m.OoredooExceptionnalAuthorizationModule), canActivate: [MsalGuard] },
            { path: 'ooredoo-invoice', data: { breadcrumb: "Consultation des factures" }, loadChildren: () => import('./main/components/supplier-invoice/supplier-invoice.module').then(m => m.SupplierInvoiceModule), canActivate: [MsalGuard] },
        ],
        canActivate: [MsalGuard]
    },
    { path: '', loadChildren: () => import('./main/components/landing/landing.module').then(m => m.LandingModule) },
    { path: 'notfound', loadChildren: () => import('./main/components/notfound/notfound.module').then(m => m.NotfoundModule) },
    { path: 'access-denied', loadChildren: () => import('./main/components/access-denied/access-denied.module').then(m => m.AccessDeniedModule) },
    { path: 'offline-exception', loadChildren: () => import('./main/components/offline-exception/offline-exception.module').then(m => m.OfflineExceptionModule) },
    { path: '**', redirectTo: '/notfound' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
