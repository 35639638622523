import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'app-sidebar',
    templateUrl: './app.sidebar.component.html',
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateX({{direction}}%)' }),
                animate('400ms ease-in', style({ transform: 'translateX(0%)' })),
            ]),
        ])
    ]
})
export class AppSidebarComponent {

    timeout: any = null;

    @ViewChild('menuContainer') menuContainer!: ElementRef;

    @Input() userAD: any | undefined;

    constructor(
        public layoutService: LayoutService,
        public el: ElementRef
    ) { }

    onMouseEnter() {
        if (!this.layoutService.state.anchored) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            this.layoutService.state.sidebarActive = true;
        }
    }

    onMouseLeave() {
        if (!this.layoutService.state.anchored) {
            if (!this.timeout) {
                this.timeout = setTimeout(() => this.layoutService.state.sidebarActive = false, 300);
            }
        }
    }

    anchor() {
        this.layoutService.state.anchored = !this.layoutService.state.anchored;
    }

}
