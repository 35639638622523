<!-- Ngx-loading-bar at the top ot the display area -->
<ngx-loading-bar [includeSpinner]="false" color="rgb(29 112 183 / 40%)" height="4px" ref="http"></ngx-loading-bar>
<div class="layout-topbar shadow-1 border-round">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>

        <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
    </div>
    <div class="layout-topbar-menu-section">
        <app-sidebar [userAD]="userAd"></app-sidebar>
    </div>

    <div class="topbar-end">
        <ul class="topbar-menu" *ngIf="isLoggedIn">
            <li #notification class="profile-item topbar-item mt-2 mr-2" style="display: flow-root !important">
                <a *ngIf="userAd" pRipple class="cursor-pointer" (click)="toggleSidebarNotification()">
                    <i class="pi pi-bell text-2xl grow" pBadge severity="danger" value="{{ _notificationLength }}"></i>
                </a>
            </li>
            <li class="profile-item topbar-item">
                <a class="cursor-pointer" *ngIf="userAd; else loadingLabel">
                    <p class="font-medium text-800 m-0" style="line-height: 0.7; text-wrap: nowrap">
                        {{ userAd?.name }}
                    </p>
                    <span class="font-normal text-800 text-sm" style="text-wrap: nowrap">
                        {{ userAd?.jobTitle }} &nbsp;|&nbsp;<span class="font-normal">{{ userAd?.location }}</span>
                    </span>
                </a>
                <ng-template #loadingLabel>
                    <p-skeleton width="14rem" height="3rem"></p-skeleton>
                </ng-template>
            </li>
            <li class="profile-item ml-3 mr-3">
                <button *ngIf="userAd; else loadingL" pButton pRipple icon="pi pi-sign-out" class="p-button-danger" (click)="logout($event)"></button>
                <p-confirmPopup key="confirm"></p-confirmPopup>
                <ng-template #loadingL>
                    <p-skeleton size="3rem" styleClass="mr-2"></p-skeleton>
                </ng-template>
            </li>
        </ul>
    </div>
</div>
