import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1
    ||
    window.navigator.userAgent.indexOf('Trident/') > -1;

export const auth_sso_configuration: Configuration = {
    auth: {
        clientId: '20e59293-cbe4-43da-a6a4-9090d7b8b14c',
        authority: 'https://login.microsoftonline.com/20923150-f613-4b38-af27-a5dd1cb27af1',
        redirectUri: '/signin-oidc',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
        secureCookies: true
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                // console.log(logLevel, message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: true,
        },
    },
};

export const AUTH_SSO_MSAL = {
    protectedResourceMap: new Map([
        ['https://prodooredooapi.azurewebsites.net/*', ['api://f1cca2df-52e6-4dda-8bce-2c48837ad2e3/access_as_user']],
        ['https://intooredooapi.azurewebsites.net/*', ['api://f1cca2df-52e6-4dda-8bce-2c48837ad2e3/access_as_user']],
        ['https://devooredooapi.azurewebsites.net/*', ['api://f1cca2df-52e6-4dda-8bce-2c48837ad2e3/access_as_user']],
        ['https://localhost:7181/*', ['api://f1cca2df-52e6-4dda-8bce-2c48837ad2e3/access_as_user']],
        ['http://localhost:4200/*', ['api://f1cca2df-52e6-4dda-8bce-2c48837ad2e3/access_as_user']],
    ]),
};
