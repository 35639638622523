import { Input, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { UserAdService } from '../main/service/user-ad.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: MenuItem[] = [];
    hasAccesToInvoice: boolean = false;

    finalMenuItemsCollections: MenuItem[] = [];

    @Input() userConnected: any;

    isStore: boolean | undefined;

    constructor(private _userAD: UserAdService) {
        this._userAD.isStore$
            .subscribe(
                {
                    next: (__) => {
                        this.isStore = __;
                    }
                }
            )
    }

    ngOnInit() {
        debugger
        let userGroupsStringCollections: string[] = [];
        if (this.userConnected) {
            this.userConnected.userGroups.map(
                (_: any) => {
                    if (_) {
                        userGroupsStringCollections.unshift(_.displayName);
                    }
                }
            );
        }
        if (userGroupsStringCollections.includes('Invoice Management') || userGroupsStringCollections.includes('Invoice Management')) { this.hasAccesToInvoice = true; } else { this.hasAccesToInvoice = false; }
        if (this.isStore === true) {
            this.model = [
                {
                    label: "Nouvelle demande",
                    fragment: 'assets/demo/images/new-purchase.png',
                    routerLink: ['/app-layout/ooredoo-operations/new-purchase-request/search-employee']
                }
                ,
                {
                    label: "Suivi Dérogations",
                    fragment: 'assets/demo/images/question.png',
                    routerLink: ['/app-layout/ooredoo-exceptionnal-authorization/exceptionnal-requests']
                }
                ,
                {
                    label: 'Suivi Demandes',
                    fragment: 'assets/demo/images/purchases.png',
                    routerLink: ['/app-layout/ooredoo-tracking/requests']
                }
                ,
                {
                    label: 'Reporting',
                    fragment: 'assets/demo/images/valid-purchases.png',
                    routerLink: ['/app-layout/ooredoo-tracking/reporting']
                }
            ];
        } else {
            this.model = [
                {
                    label: "Suivi Dérogations",
                    fragment: 'assets/demo/images/warning.png',
                    routerLink: ['/app-layout/ooredoo-exceptionnal-authorization/exceptionnal-requests']
                }
                ,
                {
                    label: 'Suivi Demandes',
                    fragment: 'assets/demo/images/purchases.png',
                    routerLink: ['/app-layout/ooredoo-tracking/requests']
                }
                ,
                {
                    label: 'Reporting',
                    fragment: 'assets/demo/images/valid-purchases.png',
                    routerLink: ['/app-layout/ooredoo-tracking/reporting']
                },
                {
                    label: 'Suivi Factures',
                    visible: this.hasAccesToInvoice,
                    fragment: 'assets/demo/images/invoice.png',
                    routerLink: ['/app-layout/ooredoo-invoice/home']
                }
            ];
        }

    }
}
